.auditoryContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 5em;
    position: relative;
    z-index: 3;
}

.containerData {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 3;
}

.containerText {
    display: flex;
    flex-direction: column;
    max-width: 752px;
    margin-bottom: 3em;
    position: relative;
    z-index: 3;
}

.noMargin {
    margin-bottom: 0;
}

.sMargin {
    margin-bottom: 1em;
}

.cardListContainer {
    padding: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1380px;
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    position: relative;
    z-index: 3;
}

.gradientCircleBlue {
    z-index: 2;
    max-width: 1000px;
    max-height: 1000px;
    position: absolute;
    pointer-events: none;
}

.gradientPeach {
    z-index: 2;
    max-width: 1150px;
    max-height: 1150px;
    position: absolute;
    pointer-events: none;
}

.headerCircle {
    right: -10%;
    top: 90%;
}

.rightCircle {
    right: 35%;
    top: 80%;
}

@media (max-width: 1024px) {

    .auditoryContent {
        margin-top: 4em;
    }

    .additionalMargin {
        margin-top: 6em;
    }


    .gradientCircleBlue {
        max-width: 800px;
        max-height: 1050px;
    }

    .gradientPeach {
        max-width: 900px;
        max-height: 1150px;
    }
}

@media (max-width: 900px) {
    .additionalMargin {
        margin-top: 2em;
    }
    .auditoryContent {
       width: 100vw;
    }
}
